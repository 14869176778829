<template>
  <div class="scan">
    <div class="scroll-container">
      <Scaner v-on:code-scanned="codeScanned" v-on:error-captured="errorCaptured" :stop-on-scanned="true" :draw-on-found="true" :responsive="false" />
    </div>
  </div>
</template>

<script>
import Scaner from '../components/Scaner'

export default {
  name: 'Scan',
  components: {
    Scaner
  },
  data () {
    return {
      errorMessage: '',
      scanned: ''
    }
  },
  methods: {
    codeScanned ( code ) {
      const queryParams = this.$myJs.parseUrlParams( code )
      localStorage.setItem( 'checkInInfo', JSON.stringify( queryParams ) )
      this.$router.go( -1 )
      // return false
      // this.scanned = code
      // setTimeout( () => {
      //   alert( `扫码解析成功: ${ code }` )
      // }, 200 )
    },
    errorCaptured ( error ) {
      switch ( error.name ) {
        case 'NotAllowedError':
          this.errorMessage = 'Camera permission denied.'
          break
        case 'NotFoundError':
          this.errorMessage = 'There is no connected camera.'
          break
        case 'NotSupportedError':
          this.errorMessage =
            'Seems like this page is served in non-secure context.'
          break
        case 'NotReadableError':
          this.errorMessage =
            "Couldn't access your camera. Is it already in use?"
          break
        case 'OverconstrainedError':
          this.errorMessage = "Constraints don't match any installed camera."
          break
        default:
          this.errorMessage = 'UNKNOWN ERROR: ' + error.message
      }
      console.error( this.errorMessage )
      alert( '相机调用失败' )
    }
  },
  mounted () {
    let str = navigator.userAgent.toLowerCase()
    let ver = str.match( /cpu iphone os (.*?) like mac os/ )
    if ( ver && ver[ 1 ].replace( /_/g, '.' ) < '10.3.3' ) {
      alert( '相机调用失败' )
    }


    // setTimeout( () => {
    //   let checkinObj = {
    //     code: '111'
    //   }
    //   this.$router.go( -1 )
    //   localStorage.setItem( 'checkInInfo', JSON.stringify( checkinObj ) )
    // }, 1000 )

  }
}
</script>

<style lang="css" scoped>
</style>
